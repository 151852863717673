.gpa__modal_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border-radius: 4px;
  padding: 10px;
}
.gpa__modal_wrapper p {
  color: var(--gpa__Text_color_light);
  padding: 0;
  margin: 0;
}
.gpa__dark_mode.gpa__modal_wrapper p {
  color: var(--gpa__Text_color_dark);
}

.gpa__modal_title {
  background-color: var(--gpa__primary_color);
  display: inline-block;
  margin-left: -10px;
  padding: 4px 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: "Merriweather", serif;
  font-weight: 600;
  margin-bottom: 6px;
}
.gpa__dark_mode.gpa__modal_title {
  background-color: var(--gpa__bg_color_hover_dark);
}
.gpa__modal_content_wrapper h4 {
  color: #fff;
  margin: 0;
}
.gpa__dark_mode.gpa__modal_content_wrapper h4 {
  color: var(--gpa__Text_color_dark);
}
.gpa__modal_close_button_wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
.gpa__call_help_center_modal_btn {
  text-decoration: none;
}
.gpa__form_input_field {
  margin-bottom: 10px !important;
}
.gpa__form_input_field_users {
  max-height: 200px;
}
@media screen and (max-width: 768px) {
  .gpa__modal_wrapper {
    width: 90%;
    padding: 30px 30px 10px 20px;
  }
  .gpa__modal_title {
    margin-left: -20px;
  }
}

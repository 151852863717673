.gpa__logo {
  width: 150px;
  height: auto;
}

@media screen and (min-width: 768px) {
  .gpa__logo {
    width: 140px;
  }
}

.gpa__mobile_drawer {
  position: relative;
  overflow-y: auto;
}

.gpa__mobile_menu_toggle {
  position: fixed;
  right: 8px;
  top: 8px;
  padding: 10px;
  background-color: var(--gpa__primary_color);
  z-index: 1200;
  border-radius: 50%;
}
.gpa__mobile_menu_toggle svg {
  fill: #fff;
}

.gpa__dark_mode.gpa__mobile_menu_toggle {
  background-color: var(--gpa__button_bg_dark);
}

.gpa__dark_mode.gpa__mobile_menu_toggle svg {
  fill: var(--gpa__Text_color_dark);
}

@media (max-width: 786px) {
  .gpa__sidebar-toggle-btn-container {
    display: block;
  }
}

.gpa__university_card_wrapper_paper{
    padding: 10px;
    display: flex;
}
.gpa__dark_mode.gpa__university_card_wrapper_paper:hover{
    background-color: var(--gpa__bg_color_hover_dark);
}
.gpa__university_card_wrapper_paper:hover{
    background-color: var(--gpa__primary_color);
    color: #fff;
}
.gpa__university_card_univ_title_wrapper h5{
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 400;
}
 

.gpa__user_profile_card_wrapper {
  padding: 15px;
  cursor: default;
}
.gpa__user_profile_card_inner_wrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 10px 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 1px, rgba(0, 0, 0, 0.23) 0px 0px 1px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.gpa__dark_mode.gpa__user_profile_card_inner_wrapper {
  background-color: var(--gpa__App_bg_sections_dark);
}
.gpa__user_profile_card_picture_area_wrapper {
  position: relative;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: var(--gpa__button_bg_dark);
}

.gpa__user_profile_card_picture_user_name {
  width: 48px;
  height: 48px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  color: #fafafa;
  text-transform: uppercase;
  font-size: var(--gpa__avatar-size-text);
}
.gpa__user_profile_card_picture_area_wrapper img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.gpa__user_profile_card_picture_change_icon {
  position: absolute;
  right: 2px;
  bottom: -8px;
  background-color: black;
  opacity: 0.7;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  color: #fafafa;
  width: 18px !important;
  height: 18px !important;
}
.gpa__user_profile_card_content_wrapper {
  padding: 0px 5px;
}
.gpa__user_profile_card_content_wrapper h5 {
  font-size: 12px;
}
.gpa__user_profile_card_content_wrapper h4 {
  margin: 0px;
  font-size: 18px;
  text-transform: capitalize;
}

.gpa__universities_wrapper{
    padding: 30px 40px!important;

}
.gpa__dark_mode.gpa__universities_wrapper{
    background-color: var(--gpa__App_bg_sections_dark);
}
.gpa__section_title_wrapper{
    padding: 1rem 0;
    font-weight: 300;
}
.gpa__university_card_wrapper a{
    text-decoration: none;
}
.gpa__university_card_wrapper{
    padding:5px 10px;
}
.gpa__failed_to_fetch_universities_error_wrapper{
    padding: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.gpa__failed_to_fetch_universities_error_wrapper button{
    margin-left: 20px;
}

@media screen and (max-width: 600px) {
    .gpa__universities_wrapper{
        padding: 30px 20px!important;
        
    }

}
.gpa__register {
  margin-left: 5px;
  background: url('../../../assets/images/register.png') no-repeat 6px 6px #f48b35;
  width: 105px;
}

.gpa__active_link,
.gpa__register:hover {
  background-image: url('../../../assets/images/registered.png');
  background-color: var(--gpa__secondary_color);
  background-position: 72px 6px;
  text-indent: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  transition: background 0.5s, text-indent 0.5s;
  text-decoration: none;
}

.gpa__dark_mode.gpa__register {
  background: url('../../../assets/images/register.png') no-repeat 6px 6px
    var(--gpa__button_bg_dark);
}
.gpa__dark_mode.gpa__active_link,
.gpa__dark_mode.gpa__register:hover {
  background-image: url('../../../assets/images/registered.png');
  background-color: var(--gpa__button_bg_dark);
  opacity: 0.8;
  background-position: 72px 6px;
  text-indent: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  transition: background 0.5s, text-indent 0.5s;
  text-decoration: none;
}

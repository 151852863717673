.gpa__home_search_section_search_bar_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  z-index: 16;
  border-radius: 3px;
  background-color: #fff;
  padding: 0 15px;
  height: 62px;
  color: #4e657e;
  margin-top: 20px;
  box-shadow: var(--gpa__box_shadow);
}
.gpa__dark_mode.gpa__home_search_section_search_bar_wrapper {
  background-color: var(--gpa__App_bg_dark);
}
.gpa__home_search_section_search_bar_wrapper.gpa__results_dropdown_show {
  box-shadow: 0;
}
.gpa__home_search_bar_icon {
  display: block;
  margin-right: 15px;
  color: #9ea9b5;
  font-size: 22px;
  width: 1rem;
}
.gpa__home_search_section_search_results_wrapper {
  position: relative;
  width: 100%;
}

.gpa__home_search_bar_input_area {
  outline: none;
  border: 0;
  border-radius: 3px;
  background-color: transparent;
  padding: 0;
  width: 100%;
  height: 100%;
  line-height: 100%;
}
.gpa__home_search_section_search_results_dropdown {
  display: none;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  margin: 0;
  border: 0;
  background-color: #fff;
  list-style: none;
  padding-left: 0;
  z-index: 1;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%), 0 0 0 1px rgb(0 0 0 / 8%);
  max-height: 300px;
  overflow-y: auto;
}
.gpa__no_search_found_wrapper {
  padding: 8px;
}
.gpa__dark_mode.gpa__home_search_section_search_results_dropdown {
  background-color: var(--gpa__App_bg_dark);
}
.gpa__home_search_section_search_results_dropdown a {
  text-decoration: none;
  text-transform: capitalize;
}
.gpa__home_search_section_search_results_dropdown_show {
  display: block;
  z-index: 1;
  border: 1px solid #e6e9ec;
}
.gpa__dark_mode.gpa__home_search_section_search_results_dropdown_show {
  border: 1px solid var(--gpa__App_bg_sections_dark);
}

/*============CUSTOM SEARCH RESULT===============*/

.gpa__custom_search_result_wrapper {
  text-align: left;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.gpa__custom_search_result_wrapper:hover {
  background-color: #f2f3f5;
}
.gpa__dark_mode.gpa__custom_search_result_wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.gpa__dark_mode.gpa__custom_search_result_wrapper:hover {
  background-color: var(--gpa__bg_color_hover_dark);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.gpa__custom_search_result_inner {
  padding: 0 2px;
}
.gpa__result_url,
.gpa__result_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.gpa__result_title {
  font-size: 14px;
  text-transform: capitalize;
}
.gpa__dark_mode.gpa__custom_search_result_wrapper .gpa__result_title {
  color: rgba(255, 255, 255, 0.85);
}
.gpa__dark_mode.gpa__custom_search_result_wrapper:hover .gpa__result_title {
  color: var(--gpa__primary_color);
}
.gpa__result_url {
  font-size: 12px;
  color: var(--gpa__secondary_color);
  text-transform: lowercase;
}

.gpa__search_result_count {
  font-size: 12px;
  height: 20px;
  min-width: 20px;
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5722;
  color: #fff;
  border-radius: 50px;
  padding: 5px;
  box-sizing: border-box;
  line-height: 1;
  font-weight: 600;
  margin-right: 10px;
  margin-left: 10px;
}

.gpa__register_wrapper_loggin{
    max-width: 450px;

}
.gpa__password_reset_link_wrapper{
    text-align: left;
    padding: 10px 10px 0;
}
.gpa__password_reset_link_wrapper a{
    text-decoration: none;
}
.gpa__dark_mode.gpa__password_reset_link_wrapper a{
    color: var(--gpa__secondary_color);
}
.gpa__password_reset_link_wrapper a:hover{
    text-decoration: underline;
}
.gpa__direction_message_wrapper{
    display: flex;
    justify-content: center;
    background-color: red;
}
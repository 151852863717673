.gpa__login {
  background: url('../../../assets/images/btn-login.png') no-repeat 7px 6px
    var(--gpa__primary_color);
  min-width: 95px;
}

.gpa__active_link,
.gpa__login:hover {
  background-image: url('../../../assets/images/btn-login-hover.png');
  background-color: var(--gpa__secondary_color);
  background-position: 62px 6px;
  text-indent: 15px !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  transition: background 0.5s, text-indent 0.5s;
  text-decoration: none;
}

.gpa__dark_mode.gpa__login {
  background: url('../../../assets/images/btn-login.png') no-repeat 7px 6px
    var(--gpa__button_bg_dark);
}
.gpa__dark_mode.gpa__active_link,
.gpa__dark_mode.gpa__login:hover {
  background-image: url('../../../assets/images/btn-login-hover.png');
  background-color: var(--gpa__button_bg_dark);
  opacity: 0.8;
  background-position: 62px 6px;
  text-indent: 15px !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  transition: background 0.5s, text-indent 0.5s;
  text-decoration: none;
}

.gpa__show_large {
  display: none;
}
.gpa__show_small {
  display: flex;
}

@media screen and (min-width: 600px) {
  .gpa__show_large {
    display: flex;
  }
  .gpa__show_small {
    display: none;
  }
}

.gpa__AppBar_wrapper {
  z-index: 1100;
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  margin-bottom: 30px;
  padding: 0;
  background-color: var(--gpa__primary_color);
}

.gpa__dark_mode.gpa__AppBar_wrapper {
  background-color: var(--gpa__AppBar_bg_dark);
}

.gpa__AppBar_logo_Wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  float: left;
}
.gpa__AppBar_spacer {
  flex: 1;
}

@media (max-width: 600px) {
  .gpa__AppBar_logo_Wrapper img {
    margin-left: -20px;
  }
}

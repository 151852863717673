.gpa__mobile_drawer_footer_wrapper{
    background-color: #f5f5f5;
    padding: 10px;
    
}
.gpa__dark_mode.gpa__mobile_drawer_footer_wrapper{
  background-color: var(--gpa__App_bg_sections_dark);
}
.gpa__mobile_drawer_footer_inner_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 1px , rgba(0, 0, 0, 0.23) 0px 0px 1px;
    padding: 5px;
   
  }
  .gpa__mobile_drawer_footer_content_wrapper {
    display: flex;
    padding: 10px 5px;
    
}
.gpa__mobile_drawer_footer_content_wrapper h5 {
    font-size: 12px;
    margin-bottom: 15px;
}
.gpa__mobile_drawer_footer_content_wrapper h4 {
    font-size: 16px;
    text-transform: capitalize;
}
.gpa__mobile_drawer_footer_membership_wrapper{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    text-align: center;
}

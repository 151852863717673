.gpa__footer_section_accordion {
  border-top-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  margin: 12px 0;
}
.gpa__mobile_footer_section_collapse_wrapper {
  display: block !important;
}
.gpa__footer_section_accordion::before {
  border: 0 !important;
  display: none;
}


.gpa__default_footer_navigation a{
    color: var(--gpa__Text_color_light);
}
.gpa__dark_mode.gpa__default_footer_navigation a{
    color: var(--gpa__Text_color_dark);

}
.gpa__default_footer_navigation a:hover li,
.gpa__default_footer_navigation a:hover .gpa__footer_section_nav_item_icon{
    color: var(--gpa__primary_color);
}

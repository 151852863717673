.gpa__section_heading_wrapper{
    padding: 20px 40px!important;

}
.gpa__section_heading_wrapper h3{
    margin-bottom: .5rem;
    /* font-weight: 300; */
}

@media screen and (max-width: 600px ) {
    .gpa__section_heading_wrapper{
        padding: 20px 20px!important;
    }
}
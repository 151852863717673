.gpa__hero_section_wrapper {
  display: block;
  margin: 0 auto 0rem;
  position: relative;
  width: 100%;
}
.gpa__hero_section_image_wrapper {
  height: 27rem;
  background-color: var(--gpa__App_bg_sections_light);
}
.gpa__dark_mode.gpa__hero_section_image_wrapper {
  background-color: var(--gpa__App_bg_sections_dark);
}
.gpa__hero_section_wrapper .gpa__hero_section_image_wrapper img {
  width: 150px;
  position: absolute;
  right: 12rem;
  top: 7rem;
}
.gpa__hero_section_wrapper .gpa__hero_section_content_wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* background: #fff; */
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
}
.gpa__hero_section_welcome_paper {
  padding: 2.4rem;
}
@media (min-width: 43.75em) {
  .gpa__hero_section_wrapper {
    max-width: 134rem;
  }
  .gpa__hero_section_wrapper .gpa__hero_section_content_wrapper {
    left: 4.8rem;
    top: 6.4rem;
    max-width: 44rem;
  }
}
@media (max-width: 43.75em) {
  .gpa__hero_section_wrapper {
    margin: 0 auto 2.4rem;
  }
  .gpa__hero_section_wrapper .gpa__hero_section_image_wrapper {
    height: 10rem;
    margin-bottom: 1.6rem;
    text-align: center;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
  .gpa__hero_section_wrapper .gpa__hero_section_image_wrapper img {
    object-fit: cover;
    position: static;
  }
  .gpa__hero_section_wrapper .gpa__hero_section_content_wrapper {
    box-shadow: none;
    position: static;
  }
  .gpa__hero_section_welcome_paper {
    padding: 1rem 2.4rem;
  }
}

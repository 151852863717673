.gpa__default_footer_section_col_wrapper {
  margin-bottom: 12px;
}
.gpa__default_footer_section_title_wrapper {
  background-color: var(--gpa__App_bg_sections_light);
  padding: 1px 16px;
  border-radius: 4px;
  border-top-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  min-height: 260px;
  overflow: auto;
}
.gpa__default_footer_section_title_wrapper h5 {
  text-transform: uppercase;
  border-left: 10px solid var(--gpa__secondary_color);
  padding-left: 5px;
}
.gpa__dark_mode.gpa__default_footer_section_title_wrapper h5 {
  border-left: 10px solid var(--gpa__App_bg_sections_dark);
}
.gpa__dark_mode.gpa__default_footer_section_title_wrapper {
  background-color: var(--gpa__App_bg_dark);
}
.gpa__default_footer_section_title_wrapper h5 {
  margin: 12px 0;
}

@keyframes _gpaPromptSlideDown {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(1000px);
    opacity: 1;
  }
}
@keyframes _gpaPromptSlideUp {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(-1000px);
    opacity: 1;
  }
}

.gpa__prompt_wrapper_membership {
  position: relative;
  background: #fff;
  width: 470px;
  padding: 6px 10px 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px,
    rgba(17, 17, 26, 0.1) 0px 24px 80px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  text-transform: initial;
  box-sizing: border-box;
  color: #333333;
}

.gpa__notification_prompt_membership {
  z-index: 99 !important;
  position: fixed;
  bottom: -1000px;
  left: calc(50% - 245px);
  font-family: Arial, Helvetica, sans-serif !important;
  transition: all 3s ease-in;
  display: block;
  opacity: 0;
  font-size: 16px !important;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {
  .gpa__notification_prompt_membership.Slide-in {
    -webkit-animation-name: _gpaPromptSlideDown;
    animation-name: _gpaPromptSlideDown;
    opacity: 1;
  }
  .gpa__prompt_wrapper_membership {
    -webkit-border-radius: 0 0 10px 10px;
    -moz-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: 768px) {
  .gpa__notification_prompt_membership {
    left: 0 !important;
    right: 0 !important;
    top: unset;
    bottom: -1000px;
    width: 100%;
    opacity: 0;
  }
  .gpa__notification_prompt_membership.Slide-in {
    -webkit-animation-name: _gpaPromptSlideUp;
    animation-name: _gpaPromptSlideUp;
    opacity: 1;
    bottom: 0px !important;
  }
  .gpa__prompt_wrapper_membership {
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    border-radius: 20px 20px 0 0;
    width: 100%;
  }
}

.gpa__mobile_footer_wrapper {
  background-color: var(--gpa__App_bg_sections_light);
  width: auto;
  padding: 20px;
}
.gpa__dark_mode.gpa__mobile_footer_wrapper {
  background-color: var(--gpa__App_bg_sections_dark);
}

.gpa__dark_mode.gpa__mobile_footer_navigation a {
  color: #fafafa;
}
.gpa__mobile_footer_navigation a {
  color: #666;
}
.gpa__mobile_footer_navigation a:hover li,
.gpa__mobile_footer_navigation a:hover .gpa__footer_section_nav_item_icon {
  color: var(--gpa__primary_color);
}
.gpa__mobile_footer_copyright_section_wrapper {
  background-color: var(--gpa__secondary_color);
  padding: 20px 20px 50px;
}
.gpa__dark_mode.gpa__mobile_footer_copyright_section_wrapper {
  background-color: var(--gpa__footer_bg_dark);
}

.gpa__footer_navigation ul {
  padding: 0;
  margin: 0;
}
.gpa__footer_navigation ul li {
  list-style: none;
  padding: 5px 5px;
  width: 100%;
}

.gpa__footer_navigation a:hover li {
  transform: translateX(4px);
  transition: transform 0.1s;
}

.gpa__footer_nav_active li {
  color: var(--gpa__primary_color);
  transform: translateX(4px);
  transition: transform 0.1s;
}

.gpa__footer_navigation a:hover .gpa__footer_section_nav_item_icon {
  transform: translateX(4px);
}
.gpa__footer_navigation a {
  text-decoration: none;
}

.gpa__footer_section_nav_item_icon {
  padding-right: 5px;
}

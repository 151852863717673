.gpa__membership_section {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  float: right;
}

.gpa__membership {
  border: none;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 5px 10px 0;
  color: white !important;
  font-size: 15px;
  font-family: 'calibri', arial;
  text-align: left;
  text-indent: 40px;
  text-decoration: none;
  transition: background 0.5s, text-indent 0.5s;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}

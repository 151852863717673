.gpa__userAccount_popper_up_wrapper {
  z-index: 1111;
  top: 8px !important;
  text-align: center;
}
.gpa__user_profile {
  background-color: var(--gpa__App_bg_sections_dark) !important;
  font-weight: 600;
  color: #fff !important;
  font-size: var(--gpa__avatar-size-text);
  text-transform: uppercase;
}
.gpa__dark_mode.gpa__user_profile {
  background-color: rgba(255, 255, 255, 0.219) !important;
}

:root {
  --gpa__primary_color: #0bdacf;
  --gpa__secondary_color: #5bc787;
  --gpa__App_bg_dark: #424242;
  --gpa__App_bg_sections_light: #fafafa;
  --gpa__App_bg_sections_dark: #333;
  --gpa__AppBar_bg_dark: #1c1d1f;
  --gpa__Text_color_light: rgba(0, 0, 0, 0.8);
  --gpa__Text_color_dark: rgba(255, 255, 255, 0.8);
  --gpa__bg_color_hover_dark: #616060dc;
  --gpa__footer_bg_dark: #1c1d1f;
  --gpa__button_bg_dark: #777777;
  --gpa__animation_dark_color: #0bdad07e;
  --gpa__highlighted_section_bg_light: #eaeaea;
  --gpa__highlighted_section_bg_dark: #2a2f3ad3;
  --gpa__dashboard_menu_bg_light: #eaeaea;
  --gpa__user_profile_dropdown_bg_dark: #424242;
  --plyr-color-main: #0bdacf;
  /* --plyr-video-background: #424242; */

  /* --gpa__chat_bg_light: #111b21; */
  --gpa__chat_bg_dark: #111b21;
  --gpa__chat_header_bg_light: #e7edf3;
  --gpa__chat_header_bg_dark: #202c33;
  --gpa__border_color_light: #eee;
  --gpa__border_color_dark: rgba(134, 150, 160, 0.15);
  --gpa__box_shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);

  /***DARK MODE COLORS***/
  --bg-content-sidedraw: #212322;
  --mode-switch-color: #777777;
  --icon-colo: #cccccc;
  --link-color: #849ba8;
  --social-icon-bg: #4b4b4b;
  --error-modal: #252525;
  --text-secondary-color: #838383;
  --drk-card-hover: #3c4043;
  --gpa__dark_black: #0d0f12;

  /***TYPOGRAPHY***/
  --gpa__avatar-size-text: 1.2rem;
}

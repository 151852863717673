.gpa__home_search_section_wrapper {
  background-color: var(--gpa__secondary_color);
  padding-top: 40px;
  padding-bottom: 80px;
}
.gpa__dark_mode.gpa__home_search_section_wrapper {
  background-color: var(--gpa__App_bg_sections_dark);
  border-top: 10px solid var(--gpa__App_bg_dark);
}
.gpa__home_search_section_title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 35px;
  font-weight: normal;
}
.gpa__home_search_section_subtitle {
  margin-top: 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  .gpa__dark_mode.gpa__home_search_section_wrapper {
    border: none;
  }
}

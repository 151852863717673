.gpa__social_icons_wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px;
}
.gpa__social_icon_link {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: inline-flex;
  padding: 5px;
  align-items: center;
  border-radius: 40px;
  color: var(--gpa__App_bg_sections_dark);
}
.gpa__dark_mode.gpa__social_icon_link {
  background-color: rgba(255, 255, 255, 0.12);
  color: var(--gpa__App_bg_sections_light);
}

.gpa__social_icon_link:hover,
.gpa__social_icon_link:focus {
  color: var(--gpa__primary_color);
  background-color: #303030;
}

.gpa__social_icon_link svg {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .gpa__social_icons_wrapper {
    justify-content: center;
  }

  .gpa__social_icon_link {
    color: var(--gpa__App_bg_sections_light);
  }
}

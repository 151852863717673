.gpa__back_to_top{
    background-color: var(--gpa__secondary_color)!important;
    color: #fff!important;
}
.gpa__back_to_top:hover{
    background-color: var(--gpa__primary_color)!important;
}

.gpa__dark_mode.gpa__back_to_top{
    background-color: var(--gpa__bg_color_hover_dark)!important;
}
.gpa__dark_mode.gpa__back_to_top:hover{
    background-color: var(--gpa__App_bg_dark)!important;
}
.gpa__back_to_top_icon{
    transform: rotate(-90deg);
}
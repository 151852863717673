.gpa__contacts_section_wrapper a{
    text-decoration: none;
    color: #1B3058;
}
.gpa_dark_mode.gpa__contacts_section_wrapper a{
    color: var(--gpa__Text_color_dark);
}
.gpa__contacts_section_wrapper a:hover{
    color: var(--gpa__primary_color);

}
.gpa__contacts_section_wrapper p{
    font-size: 14px;
}

.gpa__contacts_section_wrapper p, 
.gpa__contacts_section_wrapper h6 {
    padding:5px 12px;
    margin: 0;
}
.gpa__contacts_section_wrapper h6{
    font-size: 16px;
    font-weight: 300;
}
.gpa__userAccount_setting_wrapper {
  text-align: center;
  padding: 0px 5px 10px;
  left: 10px;
}

.gpa__userAccount_setting_wrapper nav {
  text-align: left;
  padding: 0 10px 10px;
}
.gpa__userAccount_setting_wrapper nav li {
  list-style: none;
  padding: 5px 0;
}
.gpa__userAccount_setting_wrapper ul {
  padding: 0;
  margin: 0;
}
.gpa__userAccount_setting_wrapper nav a {
  color: var(--gpa__Text_color_light);
  text-decoration: none;
  text-transform: capitalize;
}
.gpa__userAccount_setting_wrapper svg {
  color: var(--gpa__Text_color_light);
}
.gpa__dark_mode.gpa__userAccount_setting_wrapper svg {
  color: var(--gpa__Text_color_dark);
}
.gpa__userAccount_setting_wrapper nav a:hover li {
  color: var(--gpa__secondary_color);
  transform: translateX(4px);
  transition: transform 0.1s;
}
.gpa__userAccount_setting_wrapper nav a:hover svg {
  color: var(--gpa__secondary_color);
}
.gpa__dark_mode.gpa__userAccount_setting_wrapper nav a {
  color: var(--gpa__Text_color_dark);
}

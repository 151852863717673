* {
  padding: 0;
  margin: 0;
}
.gpa__bg_light_mode {
  background-color: var(--gpa__App_bg_sections_light) !important;
}
.gpa__bg_dark_mode {
  background-color: var(--gpa__App_bg_sections_dark) !important;
}

.gpa__text {
  color: var(--gpa__Text_color_dark);
}
.gpa__dark_mode.gpa__text {
  color: var(--gpa__Text_color_light);
}

.rpv-core__tooltip-body {
  display: none;
}
.rpv-core-inner-page {
  background: none;
}

/****************************
  TAILWIND CSS
  ****************************/
@tailwind base;
@tailwind components;
@tailwind utilities;

/****************************
  MAIN PAGE POPULAR SECTION
  ****************************/
.MuiTab-wrapper {
  display: flex !important;
  flex-direction: row !important;
}

.MuiTab-wrapper svg {
  padding-right: 10px;
  font-size: 34px;
}
.rec.rec-arrow:disabled {
  visibility: hidden;
}

:focus-visible {
  outline: none !important;
}

/****************************
  MUI CSS
  ****************************/
.MuiFormLabel-root {
  font-size: 18px !important;
}
.css-gejgfv-MuiPaper-root,
.css-gq10j2-MuiPaper-root {
  background-image: none !important;
}

.MuiModal-backdrop,
.css-919eu4,
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

iframe {
  max-width: 100% !important;
}

@media screen and (max-width: 600px) {
  .MuiTab-wrapper {
    flex-direction: column !important;
  }
  .MuiTab-wrapper svg {
    padding-right: 0px;
    font-size: 24px;
  }
}

/****************************
  COURSE UNIT CAROUSEL
  ****************************/

.rec-carousel-wrapper {
  position: relative !important;
}
.rec-arrow {
  position: absolute !important;
  top: 0;
  z-index: 9;
  box-shadow: none !important;
}

.rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
  color: #fff;
  background-color: var(--gpa__primary_color) !important;
}
.rec-arrow-left {
  left: 0;
}
.rec-arrow-right {
  right: 0;
}

.CourseUnitCarousel_gpa__dark_mode__1dFUT .rec-arrow {
  background-color: var(--gpa__button_bg_dark) !important;
  color: #fff !important;
}
.CourseUnitCarousel_gpa__dark_mode__1dFUT .rec-arrow:hover:enabled,
.CourseUnitCarousel_gpa__dark_mode__1dFUT .rec-arrow:focus:enabled {
  background-color: var(--gpa__bg_color_hover_dark) !important;
  color: #fff !important;
}

.ytp-expand-pause-overlay.ytp-pause-overlay {
  display: none !important;
}

/* Dashboard Users */
.MuiListItemText-primary {
  text-transform: capitalize;
}
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  background-color: var(--gpa__App_bg_dark);
  background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  border-radius: 100px;
}

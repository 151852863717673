.gpa__registration_section {
  position: relative;
  padding-top: 23px;
}
.gpa__registration_section:last-child {
  padding-bottom: 46px;
}
.gpa__membership_section_title {
  text-align: center;
  color: #4e657e;
}
.gpa__dark_mode.gpa__membership_section_title {
  color: rgba(255, 255, 255, 0.6);
}
.gpa__register_wrapper {
  border-radius: 3px;
  text-align: center;
  margin: 30px auto 0;
}
.gpa__register_wrapper_inner {
  padding: 30px 15px 0;
}
.gpa__form_input_field {
  margin-bottom: 10px !important;
  text-align: left;
}

.gpa__register_submit_button {
  width: auto;
  margin: 20px 0 !important;
}
.gpa__dark_mode.gpa__register_submit_button {
  background-color: var(--gpa__button_bg_dark);
}
.gpa__dark_mode.gpa__register_submit_button:hover {
  background-color: var(--gpa__bg_color_hover_dark);
}

.gpa__register_form_footer {
  display: flex;
  margin-top: 0;
  background-color: transparent;
  justify-content: center;
  padding: 10px;
  background-color: #eaeaea;
  width: 100%;
}
.gpa__register_form_footer a {
  text-decoration: none;
}
.gpa__dark_mode.gpa__register_form_footer {
  background-color: var(--gpa__highlighted_section_bg_dark);
}
.gpa__dark_mode.gpa__register_form_footer a {
  color: var(--gpa__secondary_color);
}

@media (min-width: 576px) {
  .gpa__form_input_field.gpa__form_input_field_last {
    margin-bottom: 0 !important;
  }
}

.gpa__home_search_section_search_results_wrapper {
  position: relative;
  width: 100%;
  z-index: 55;
}

.gpa__home_search_section_search_results_dropdown {
  display: none;
  position: absolute;
  top: -8px;
  right: 0;
  left: 0;
  margin: 0;
  border: 0;
  background-color: #fff;
  list-style: none;
  padding-left: 0;
  z-index: 55;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%), 0 0 0 1px rgb(0 0 0 / 8%);
  max-height: 200px;
  overflow-y: auto;
}
.gpa__no_search_found_wrapper {
  padding: 8px;
}
.gpa__dark_mode.gpa__home_search_section_search_results_dropdown {
  background-color: var(--gpa__App_bg_dark);
}
.gpa__home_search_section_search_results_dropdown a {
  text-decoration: none;
  text-transform: capitalize;
}
.gpa__home_search_section_search_results_dropdown_show {
  display: block;
  z-index: 1;
  border: 1px solid #e6e9ec;
}
.gpa__dark_mode.gpa__home_search_section_search_results_dropdown_show {
  border: 1px solid var(--gpa__App_bg_sections_dark);
}

/*============CUSTOM SEARCH RESULT===============*/

.gpa__custom_search_result_wrapper {
  text-align: left;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.gpa__custom_search_result_wrapper:hover {
  background-color: #f2f3f5;
}
.gpa__dark_mode.gpa__custom_search_result_wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.gpa__dark_mode.gpa__custom_search_result_wrapper:hover {
  background-color: var(--gpa__bg_color_hover_dark);
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.gpa__custom_search_result_inner {
  padding: 0 2px;
}
.gpa__result_url,
.gpa__result_title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.gpa__result_title {
  font-size: 14px;
  text-transform: capitalize;
}
.gpa__dark_mode.gpa__custom_search_result_wrapper .gpa__result_title {
  color: rgba(255, 255, 255, 0.85);
}
.gpa__dark_mode.gpa__custom_search_result_wrapper:hover .gpa__result_title {
  color: var(--gpa__primary_color);
}
.gpa__result_url {
  font-size: 12px;
  color: var(--gpa__secondary_color);
  text-transform: capitalize;
}

.gpa__dashboard_menu_item_link_wrapper {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.945);
  padding: 2px 0;
}

.gpa__dashboard_menu_item_wrapper {
  position: relative;
  padding: 8px;
  cursor: pointer;
  width: 95%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--gpa__Text_color_light);
}

.gpa__dashboard_menu_item_count {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2px;
  background-color: #ff5722;
  color: #fff;
  height: 20px;
  min-width: 20px;
  border-radius: 50px;
  padding: 2px;
  font-size: 12px;
  text-align: center;
}
.gpa__dark_mode.gpa__dashboard_menu_item_count {
  background-color: #8a1f1f;
}
.gpa__dark_mode.gpa__dashboard_menu_item_link_wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.gpa__dashboard_menu_item_wrapper:hover,
.gpa__dark_mode.gpa__dashboard_menu_item_wrapper:hover {
  color: var(--gpa__primary_color);
}
.gpa__selected_menu_item {
  color: #fff !important;
  background-color: var(--gpa__primary_color);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.gpa__dark_mode.gpa__dashboard_menu_item_wrapper {
  color: var(--gpa__Text_color_dark);
}

.gpa__dark_mode.gpa__selected_menu_item {
  color: var(--gpa__primary_color) !important;
  background-color: var(--gpa__button_bg_dark);
}

.gpa__dashboard_menu_item_icon_wrapper {
  margin-right: 10px;
}
.gpa__dashboard_menu_item_title_wrapper {
  text-transform: capitalize;
  position: relative;
}
.gpa__new::after,
.gpa__beta::after {
  content: '';
  right: -40px;
  position: absolute;
  background-color: var(--gpa__secondary_color);
  color: #fff;
  width: 35px;
  border-radius: 20px;
  font-size: 10px;
  text-align: center;
  font-style: italic;
}
.gpa__beta::after {
  content: 'Beta';
}
.gpa__new::after {
  content: 'New';
}
.gpa__dark_mode.gpa__new::after,
.gpa__dark_mode.gpa__beta::after {
  background-color: #0bdad0af;
  color: #fff;
}

/**
 * Header Styles
 */

.header {
  &-navigation-item {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    position: relative;

    &__dropdown {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      right: -5px;
      top: 40px;
      padding-left: 0;
      -webkit-transition: all 250ms ease-out;
      -moz-transition: all 250ms ease-out;
      -o-transition: all 250ms ease-out;
      transition: all 250ms ease-out;
      background-color: white;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      -webkit-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
      //   color: #202020;
      list-style: none;
      margin-top: 8px;
      min-width: 225px;
      max-width: 350px;
      position: absolute;
      z-index: 4;

      &::before {
        position: absolute;
        width: 100%;
        height: 9px;
        content: '';
        left: 0;
        top: -9px;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: white;
        top: -16px;
        right: 5%;
        z-index: 4;
      }

      &--active {
        opacity: 1;
        visibility: visible;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
        pointer-events: all;
        animation: slideInUp 0.5s ease-in-out;
      }
    }
    &__dropdown.gpa__dark_mode {
      background-color: var(--gpa__user_profile_dropdown_bg_dark);
      color: white;

      &::after {
        content: '';
        border-bottom-color: var(--gpa__user_profile_dropdown_bg_dark);
      }
    }
  }
}

@keyframes slideInUp {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
  }
}

.gpa__sidedrawer_header_wrapper {
  padding: 0px;
  background-color: var(--gpa__primary_color);
}

.gpa__dark_mode.gpa__sidedrawer_header_wrapper {
  background-color: var(--gpa__AppBar_bg_dark);
}
.gpa__sidedrawer_header_theme_mode_switch_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  background-color: aliceblue;
  color: #4e657e;
}
.gpa__dark_mode.gpa__sidedrawer_header_theme_mode_switch_wrapper {
  background-color: var(--gpa__highlighted_section_bg_dark);
  color: rgba(255, 255, 255, 0.6);
}
.gpa__logo {
  width: 240px;
}

.gpa__mobile_drawer_footer {
  display: block;
  width: 100%;
}

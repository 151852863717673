.gpa__side_menu_wrapper {
  display: block;
  max-width: 250px;
}

.gpa__university {
  text-decoration: none;
  color: var(--gpa__secondary_color);
  text-transform: capitalize;
}
.gpa__mobile_sidebar_menu_item {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding: 10px 10px !important;
  width: 100%;
}

.gpa__mobile_sidebar_menu_item_list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 12px;
}
.gpa__mobile_sidebar_menu_item_list ul {
  padding: 0;
  margin: 0;
}
.gpa__mobile_sidebar_menu_item_list ul a {
  color: var(--gpa__secondary_color);
}
.gpa__mobile_sidebar_menu_item_list ul a:hover {
  color: var(--gpa__primary_color);
}

.gpa__mobile_sidebar_menu_item_list ul li {
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  padding-right: 10px;
}
.gpa__dark_mode.gpa__mobile_sidebar_menu_item_list ul li {
  border-bottom: 1px solid rgb(88, 86, 86);
}

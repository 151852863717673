.gpa__popular_tabs_wrapper{
    background-color: #fff;
    margin-bottom: 10px;
}
.gpa__dark_mode.gpa__popular_tabs_wrapper{
    background-color: var(--gpa__App_bg_dark);
}
.gpa__popular_item_tab{
    padding-right: 10px;
}
.gpa__popular_content_area{
    background-color: var(--gpa__App_bg_sections_light);
}
.gpa__dark_mode.gpa__popular_content_area{
    background-color: var(--gpa__App_bg_sections_dark);
}
.gpa__popular_content_area a{
    text-decoration: none;
}
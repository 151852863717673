.gpa__link_item_bagde{
    position: relative;
  }
  .gpa__link_item_bagde_message_wrapper{
    position: absolute;
    top: 5px;
    background-color: #ff0000;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 5px;
    color: #fff;
    display: inline-block;
    text-align: center;
  
  }
  .gpa__dark_mode.gpa__link_item_bagde_message_wrapper{
    background-color: #ff00005d;
  
  }
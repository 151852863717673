.gpa__toggle_button{
    height:30px;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    width:30px;
    border:none;
    background:transparent;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: 0px;
    padding: 2px;
}

.gpa__toggle_button_item {
    width:25px;
    height: 2px;
    background: var(--gpa__App_bg_sections_light);

}

.gpa__dark_mode .gpa__toggle_button_item{
    background-color: var(--gpa__App_bg_sections_light);
}


